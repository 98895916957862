<template>
    <v-card width="94vw" v-if="container && booking" style="contain: content">
      <v-toolbar
        dense
        flat
        style="background-color: var(--v-toolbar-lighten1) !important"
      >
        <v-toolbar-title
          >Container:
          <b>{{ container.ctoNo ? container.ctoNo : "" }}</b></v-toolbar-title
        > <v-chip class="ml-2" small v-if="container.booking.user">Created By: <v-icon class="ml-1" left small>person</v-icon> {{ container.booking.user.firstname }} {{ container.booking.user.surname }}</v-chip>
        <v-spacer></v-spacer>
        <v-chip :color="getStatusColor(container.transportStatus)">
          <div>{{ container.transportStatus ? container.transportStatus : "No Status" }}</div>
        </v-chip>
        <v-btn
          class="ml-2"
          icon
          color="secondary"
          :disabled="!change"
          :loading="savingChange"
          @click="updateContainer()"
          ><v-icon>save</v-icon></v-btn
        >
        <v-btn icon @click="closeDialog()"><v-icon>close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text class="pt-3">
        <v-row>
          <v-col cols="12" sm="8">
            <v-row>
              <v-col cols="12" sm="6">
            <v-card
              width="100%"
              height="75vh"
              class="my-3"
              :loading="loadingDetails"
              style="background-color: var(--v-toolbar-lighten1) !important; max-height:75vh; overflow-y: auto"
            >
            <!-- <v-subheader style="font-size: 16px">
                <v-icon class="mr-2" color="secondary"> local_shipping </v-icon>
                Assigned Transporter
              </v-subheader>
            <v-select
                  :disabled="container.isCarrierHaulage"
                  :items="contractors" item-text="name" rounded :loading="loadingContractors"
                  :placeholder="'Select Transporter'"
                  item-value="id"
                  style="background-color: primary; margin: 1vh 1vw 0.5vh 1vw"
                  hide-details
                  outlined
                  return-object
                  @change="updateTransporter"
                  v-model="container.transporter"
                  clearable
                  dense
                >
                  <template v-slot:selection="data">
                    <v-row align="center">
                      <v-avatar size="32" class="mr-2" v-if="data.item">
                        <v-img
                          v-if="data.item.logo"
                          contain
                          :src="data.item.logo"
                        ></v-img>
                      </v-avatar>
                      <span v-if="data.item">
                        {{ data.item.name }}
                      </span>
                    </v-row>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <v-img
                        contain
                        v-if="data.item.logo"
                        :src="data.item.logo"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select> -->
  
                <div v-if="container.PLUG_IN && container.PLUG_IN.isActive && !container.PLUG_OUT ">
                <v-subheader style="font-size: 16px" >
                <v-icon class="mr-2" color="secondary"> local_shipping </v-icon>
                Cross Haul Transporter
                <!-- <v-spacer> </v-spacer>
                <v-icon class="mr-2" color="primary"> add_circle_outline </v-icon> -->
              </v-subheader>
              <v-select
                  :items="contractors" item-text="name" rounded :loading="loadingContractors"
                  :placeholder="'Select Transporter'"
                  item-value="id"
                  style="background-color: primary; margin: 1vh 1vw 0.5vh 1vw"
                  hide-details
                  outlined
                  return-object
                  @change="updateCrosshaulTransporter"
                  v-model="container.crossHaulTranporter"
                  clearable
                  dense
                >
                  <template v-slot:selection="data">
                    <v-row align="center">
                      <v-avatar size="32" class="mr-2" v-if="data.item">
                        <v-img
                          v-if="data.item.logo"
                          contain
                          :src="data.item.logo"
                        ></v-img>
                      </v-avatar>
                      <span v-if="data.item">
                        {{ data.item.name }}
                      </span>
                    </v-row>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <v-img
                        contain
                        v-if="data.item.logo"
                        :src="data.item.logo"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
                </div>
              <v-subheader style="font-size: 16px">
                <v-icon class="mr-2" color="secondary"> task_alt </v-icon>
                Milestones
              </v-subheader>
              <v-divider></v-divider>
              <v-card-text>
                <v-list dense v-if="milestones.length == 0">
                  <v-list-item v-if="!milestones">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey">
                        No milestones
                      </span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list dense v-if="milestones && milestones.length > 0">
                  <v-list-item
                    style="height: 40px"
                    v-for="milestone in milestones"
                    :key="milestone.id"
                  >
                    <v-list-item-action>
                      <v-icon :color="milestone.isActual ? 'success' : 'grey'"
                        >fiber_manual_record</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ milestone.description }}
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 12px">
                        {{ formatDateTime(milestone.date, milestone.time) }}
                        <v-chip
                          x-small
                          class="mx-1"
                          v-if="milestoneType(milestone.type)"
                        >
                          {{ milestoneType(milestone.type) }}
                        </v-chip>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list v-if="legs.length > 0" dense>
                  <v-subheader style="font-size: 16px"
                    ><v-icon color="secondary" class="mr-2"
                      >local_shipping</v-icon
                    >
                    Transport Legs
                    <v-spacer></v-spacer>
                    <v-chip outlined style="border: none" v-if="loadedDistance"
                      ><v-icon color="blue" left>route</v-icon> Loaded Distance:
                      {{ loadedDistance }} KM</v-chip
                    >
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item
                    v-for="leg in legs"
                    :key="leg.leg"
                    class="my-0 py-0"
                  >
                    <v-list-item-action>
                      <v-chip small class="mr-2">{{ leg.leg }}</v-chip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <v-icon color="blue" small class="mr-2"
                          >location_on</v-icon
                        >
                        {{ leg.start.poi.name }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="leg.start.poi && leg.start.poi.city" style="font-size: 12px">
                       <v-icon color="grey" small class="mr-2">location_on</v-icon> {{ leg.start.poi.city }} <span v-if="leg.start.poi.country">, {{ leg.start.poi.country }}</span>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-icon color="green" small class="mr-2"
                          >where_to_vote</v-icon
                        >
                        {{ leg.end.poi.name }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="leg.end.poi && leg.end.poi.city" style="font-size: 12px">
                        <v-icon color="grey" small class="mr-2">location_on</v-icon> {{ leg.end.poi.city }} <span v-if="leg.end.poi.country">, {{ leg.end.poi.country }}</span>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-chip small class="mx-1" outlined style="border: none">
                          <v-icon color="grey" small left>route</v-icon>
                          {{ leg.distance }} KM</v-chip
                        >
                        <v-chip
                          small
                          class="mx-1"
                          outlined
                          style="border: none"
                          v-if="leg.travelTimeText"
                        >
                          <v-icon color="grey" small left>schedule</v-icon>
                          {{ leg.travelTimeText.substring(0, 5) }} Travel
                          Time</v-chip
                        >
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <!-- <gmap-map :center="center" :zoom="10" :key="key" style="width: 500px; height: 400px;"
                                  v-bind:options="mapStyle">
                                  <gmap-polyline v-for="leg in legs" :key="leg.leg" :path="leg.route" :options="{
                                      strokeColor: '#f7b568'
                                  }"></gmap-polyline>
                                  <gmap-custom-marker :offsetY="-18" v-for="location in legs" :key="location.leg"
                                      alignment="center" :marker="location.start">
                                      <div>
                                          <div
                                              style="border-radius: 50%; background-color: var(--v-primary-base) !important;border:  3px solid black">
                                              <v-avatar size="32" color="black">
                                                  <h1 style="color: white">{{ location.leg }}</h1>
                                              </v-avatar>
                                          </div>
                                          <div class="map-marker"></div>
                                      </div>
                                  </gmap-custom-marker>
                              </gmap-map> -->
              </v-card-text>
            </v-card>
          </v-col>
              <v-col cols="12" sm="6">
                <v-card
                  flat
                  class="my-3"
                  style="background-color: var(--v-toolbar-lighten1) !important"
                >
                  <v-card-text>
                    <v-col cols="12" sm="12" class="pt-0">
                      <v-subheader style="font-size: 16px" class="pl-2">
                        <v-icon class="mr-2" color="secondary"> widgets </v-icon>
                        Cargo
                      </v-subheader>
                      <v-divider></v-divider>
                      <v-list dense>
                        <v-list-item
                          v-for="cargo in container.containerProducts"
                          :key="cargo.id"
                        >
                          <v-list-item-content>
                            <v-list-item-title style="font-size: 14px">
                              <b>{{ cargo.product.name }}</b>
                              <span style="font-size: 12px" v-if="cargo.hsCode">
                                - {{ cargo.hsCode }}</span
                              >
                              <span style="font-size: 12px" v-if="cargo.quantity">
                                - {{ cargo.quantity }} {{ cargo.quantityType }}</span
                              >
                            </v-list-item-title>
                            <v-list-item-subtitle
                              v-if="cargo.description"
                              style="font-size: 12px"
                            >
                              {{ cargo.description }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle
                              style="font-size: 12px"
                              v-if="cargo.nettWeight || cargo.grossWeight"
                            >
                              NETT {{ cargo.nettWeight }} KG - GROSS
                              {{ cargo.grossWeight }} KG
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-subheader style="font-size: 16px">
                      <v-icon class="mr-2" color="secondary"> chat </v-icon>
                       Comments
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-card
                      height="45vh"
                      width="inherit"
                      style="
                        overflow-y: auto;
                        background-color: transparent !important;
                      "
                      flat
                      :loading="loadingComments"
                      id="messagesContainer"
                    >
                      <v-list dense>
                        <v-list-item
                          v-if="
                            container.containerComments &&
                            container.containerComments.length == 0
                          "
                        >
                          <v-list-item-content class="text-center">
                            <span style="font-size: 12px; color: grey">
                              No comments.
                            </span>
                          </v-list-item-content>
                        </v-list-item>
                        <div v-if="container.containerComments">
                          <v-list-item
                            v-for="(
                              comment, index
                            ) in container.containerComments"
                            :key="comment.id"
                            :id="'comment_' + index"
                            class="py-0 my-1"
                          >
                            <v-list-item-avatar
                              v-if="comment.userId != $store.state.user.id"
                            >
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-avatar
                                    v-bind="attrs"
                                    v-on="on"
                                    style="cursor: pointer"
                                    color="secondary"
                                    size="36"
                                    class="mt-0 pt-0"
                                  >
                                    <span
                                      v-if="!comment.user.avatar"
                                      class="primaryText--text text-h5"
                                      >{{
                                       $Format.avatarAbbreviation(
                                          comment.user.firstName +
                                            " " +
                                            comment.user.surname
                                        )
                                      }}</span
                                    >
                                    <img
                                      v-else
                                      :src="comment.user.avatar"
                                      referrerpolicy="no-referrer"
                                    />
                                  </v-avatar>
                                </template>
                                <span style="font-size: 12px"
                                  >{{ comment.user.firstName }}
                                  {{ comment.user.surname }}</span
                                >
                              </v-tooltip>
                            </v-list-item-avatar>
                            <v-list-item-content
                              :class="
                                comment.userId == $store.state.user.id
                                  ? 'text-right'
                                  : 'text-left'
                              "
                            >
                              <v-list-item-title class="text-wrap">
                                {{ comment.comment }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <i style="font-size: 12px; color: grey">{{
                                  formatDate(comment.createdAt).dateTime
                                }}</i>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-avatar
                              v-if="comment.userId == $store.state.user.id"
                            >
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-avatar
                                    v-bind="attrs"
                                    v-on="on"
                                    style="cursor: pointer"
                                    color="secondary"
                                    size="36"
                                    class="mt-0 pt-0"
                                  >
                                    <span
                                      v-if="!comment.user.avatar"
                                      class="primaryText--text text-h5"
                                      >{{
                                       $Format.avatarAbbreviation(
                                          comment.user.firstName +
                                            " " +
                                            comment.user.surname
                                        )
                                      }}</span
                                    >
                                    <img
                                      v-else
                                      :src="comment.user.avatar"
                                      referrerpolicy="no-referrer"
                                    />
                                  </v-avatar>
                                </template>
                                <span style="font-size: 12px"
                                  >{{ comment.user.firstName }}
                                  {{ comment.user.surname }}</span
                                >
                              </v-tooltip>
                            </v-list-item-avatar>
                          </v-list-item>
                        </div>
                      </v-list>
                    </v-card>
                  </v-card-text>
                  <v-card
                    flat
                    height="7vh"
                    width="100%"
                    class="mx-0 px-0"
                    style="
                      position: absolute;
                      bottom: 0;
                      background-color: var(--v-toolbar-lighten1) !important;
                      display: flex;
                      align-items: center;
                      overflow-y: hidden;
                    "
                  >
                    <v-textarea
                      :key="chatContainerUpdateKey"
                      persistent-placeholder
                      placeholder="Type a Message"
                      @keydown.enter="commentContainer()"
                      class="ml-2"
                      no-resize
                      hide-details
                      outlined
                      height="6vh"
                      style="width: 80%; contain: content; border-radius: 20px"
                      v-model="containerChatArea"
                      :value="containerChatArea"
                    ></v-textarea>
                    <v-btn
                      :loading="sendingMessage"
                      icon
                      class="ml-3 mr-2"
                      @click="commentContainer()"
                      ><v-icon>send</v-icon></v-btn
                    >
                  </v-card>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
  
          <v-col cols="12" sm="4">
                <v-card
                  width="100%"
                  style="
                    background-color: var(--v-toolbar-lighten1) !important;
                    margin: 1vh 0 2vh 0;
                  "
                >
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="secondary"> tag </v-icon>
                    Reference Numbers
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-row>
                      <v-col cols="12" sm="6" class="my-0 py-0">
                          <v-list-item>
                              <v-list-item-content>
                                  <v-list-item-title v-if="container.shipmentFile">
                                      {{
                        container.shipmentFile.fileNumber
                      }}
                                  </v-list-item-title>
                                  <v-list-item-title v-else>
                                      -
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                      File Number
                                  </v-list-item-subtitle>
                              </v-list-item-content>
                          </v-list-item>
                      </v-col>
                      <v-col cols="12" sm="6" class="my-0 py-0">
                          <v-list-item>
                              <v-list-item-content>
                                  <v-list-item-title v-if="container.customerContainerRef">
                                      {{
                        container.customerContainerRef
                      }}
                                  </v-list-item-title>
                                  <v-list-item-title v-else>
                                      -
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                      Customer Ref
                                  </v-list-item-subtitle>
                              </v-list-item-content>
                          </v-list-item>
                      </v-col>
                      <v-col cols="12" sm="6" class="my-0 py-0">
                          <v-text-field
                        outlined
                        dense
                        rounded
                        label="Container No"
                        v-model="container.containerNo"
                        :rules="[rules.containerNo]"
                        @input="setChange"
                      ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" class="my-0 py-0">
                          <v-text-field
                        outlined
                        dense
                        rounded
                        label="Seal No"
                        v-model="container.sealNo"
                        @input="setChange"
                      ></v-text-field>
                      </v-col>
  
                    <v-col cols="12" sm="12" class="my-0 py-0">
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          sm="6"
                          class="my-0 py-0"
                        >
                          <v-list-item>
                              <v-list-item-content>
                                  <v-list-item-title v-if="container.containerType">
                                      {{
                        container.containerType
                      }}
                                  </v-list-item-title>
                                  <v-list-item-title v-else>
                                      -
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                      Container Type
                                  </v-list-item-subtitle>
                              </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          class="my-0 py-0"
                        >
                        <v-list-item>
                              <v-list-item-content>
                                  <v-list-item-title v-if="container.verificationMethod">
                                      {{
                        container.verificationMethod
                      }}
                                  </v-list-item-title>
                                  <v-list-item-title v-else>
                                      -
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                      Verification Method
                                  </v-list-item-subtitle>
                              </v-list-item-content>
                          </v-list-item>
                        </v-col>
  
                        <v-col cols="12" sm="12" class="pl-1">
                          <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="secondary"> scale </v-icon>
                    Weights
                  </v-subheader>
                            <v-card-text class="mt-0 pt-0">
                              <v-row no-gutters>
                                <v-col cols="12" md="6">
                                  <v-text-field
                                  prefix="Nett"
                                    disabled
                                    hide-details
                                    class="mt-1"
                                    type="number"
                                    step="0.00"
                                    suffix="KG"
                                    v-model="container.nettWeight"
                                    outlined
                                    dense
                                    rounded
                                  ></v-text-field>
                                  <v-text-field
                                  prefix="Gross"
                                    disabled
                                    hide-details
                                    class="mt-1"
                                    type="number"
                                    step="0.00"
                                    suffix="KG"
                                    v-model="container.grossWeight"
                                    outlined
                                    dense
                                    rounded
                                  ></v-text-field>
  </v-col>
                                <v-col cols="12" md="6">
                                  <v-text-field
                                    prefix="VGM"
                                    hide-details
                                    suffix="KG"
                                    type="number"
                                    :step="0.00"
                                    v-model="container.vgmWeight"
                                    @input="setChange"
                                    outlined
                                    dense
                                    rounded
                                  ></v-text-field>
                                  <v-text-field
                                    prefix="Tare"
                                    hide-details
                                    class="mt-1"
                                    type="number"
                                    :step="0.00"
                                    suffix="KG"
                                    v-model="container.tareWeight"
                                    @input="setVGM"
                                    outlined
                                    dense
                                    rounded
  
                                  ></v-text-field>
                                
                                </v-col>
                              </v-row>
                            </v-card-text>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6" class="pl-1">
                      <v-subheader style="font-size: 16px" >
                        <v-icon class="mr-2" color="secondary"> router </v-icon>
                        Services 
                      </v-subheader>
                      <v-divider></v-divider>
                      <v-list dense>
                          <v-list-item v-if="container.preAdviseRequired">
                          <v-list-item-content>
                            <v-list-item-title style="font-size: 14px">
                              Preadvised
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                              <v-switch color="blue" v-model="container.preadvised" @change="setPreadvised(container.preadvised)"></v-switch>
                          </v-list-item-action>
                        </v-list-item>
  
                          <v-list-item >
                                <v-chip
                              class="mx-1"
                              small
                              :color="
                                container && container.gensetRequired ? '#cfb055' : ''
                              "
                              ><v-icon left>bolt</v-icon>
                              <span v-if="container.gensetRequired"
                                >Genset Required</span
                              >
                              <span v-else>No Genset Required</span></v-chip
                            >
                          </v-list-item>
  
                          <v-list-item>
                            <v-chip
                          class="mx-1"
                          small
                          :color="
                            container && container.tempRecorderType ? 'green' : ''
                          "
                          ><v-icon left>router</v-icon>
                          <span v-if="container.tempRecorderType"
                            >Devices Required: {{ container.tempRecorderType }}</span
                          >
                          <span v-else>No Devices Required</span></v-chip
                        >
                          </v-list-item>
             
                      </v-list>
                    </v-col>
                    <v-col cols="12" sm="6" class="pt-2 pl-1">
                      <v-subheader style="font-size: 16px">
                        <v-icon class="mr-2" color="secondary">
                          description
                        </v-icon>
                        Documents
                      </v-subheader>
                      <v-divider></v-divider>
                      <v-progress-linear
                        v-if="loadingDetails"
                        indeterminate
                      ></v-progress-linear>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span v-if="!container.ctoDocument">-</span>
                            <span v-else
                              >Version: {{ container.ctoDocument.version }}</span
                            >
                          </v-list-item-title>
                          <v-list-item-subtitle> CTO </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-row justify="center">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  icon
                                  @click="generateCTO(container.id, container)"
                                  :loading="loadingCto"
                                  v-on="on"
                                >
                                  <v-icon color="teal">post_add</v-icon>
                                </v-btn>
                              </template>
                              <span style="font-size: 12px"
                                >Generate new CTO</span
                              >
                            </v-tooltip>
                            <v-tooltip top v-if="container.ctoDocument">
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  icon
                                  @click="viewCTO(container.ctoDocument)"
                                  v-on="on"
                                >
                                  <v-icon color="blue">launch</v-icon>
                                </v-btn>
                              </template>
                              <span style="font-size: 12px">View Document</span>
                            </v-tooltip>
                            <v-tooltip top v-if="container.ctoDocument">
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  icon
                                  @click="downloadDocument(container.ctoDocument)"
                                  v-on="on"
                                >
                                  <v-icon>download</v-icon>
                                </v-btn>
                              </template>
                              <span style="font-size: 12px">Download CTO</span>
                            </v-tooltip>
                          </v-row>
                        </v-list-item-action>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" class="my-0 py-0">
                      <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="secondary"> ac_unit </v-icon>
                    Regime  <v-spacer></v-spacer>
                          <v-chip
                            small
                            v-if="
                              regime &&
                              regime.probeCount &&
                              regime.probeType
                            "
                            class="mx-1"
                          >
                            <span
                              >{{ regime.probeCount }} x
                              {{ regime.probeType }}</span
                            >
                          </v-chip>
                          <v-chip
                            v-if="regime && regime.steri"
                            outlined
                            style="border: none"
                            color="blue"
                            >STERI
                            <v-icon right color="blue">ac_unit</v-icon></v-chip
                          >
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-progress-linear
                    v-if="loadingDetails"
                    indeterminate
                  ></v-progress-linear>
                  <v-row justify="center" align="center" class="mt-3">
                    <v-col cols="12" class="d-flex justify-center py-0 my-0">
                          <span style="display: flex; gap: 30px">
                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                align-items: center;
                              "
                            >
                              <div
                                class="mr-3"
                                style="
                                  display: flex;
                                  flex-direction: column;
                                  justify-content: center;
                                  align-items: center;
                                "
                              >
                                <b style="font-size: large">
                                  {{ regime ? regime.code : "None" }}
                                </b>
                                <small>Code</small>
                              </div>
                            </div>
                          
                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                align-items: center;
                              "
                            >
                              <div
                                style="
                                  display: flex;
                                  flex-direction: column;
                                  justify-content: center;
                                  align-items: center;
                                "
                              >
                                <v-icon color="#007d5c">thermostat</v-icon>
                                <small>Set</small>
                              </div>
                              <b style="font-size: large; padding: 0 0 15px 0">{{
                                regime ? `${regime.setPointTemp}°C` : ""
                              }}</b>
                            </div>
                            <div
                                v-if="regime && regime.protocol"
                                style="
                                  display: flex;
                                  justify-content: center;
                                  align-items: center;
                                "
                              >
                                <div
                                  style="
                                    font-size: 12px;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                  "
                                >
                                  <v-icon color="#9d904a" small
                                    >thermostat</v-icon
                                  >
                                  <small>Protocol</small>
                                </div>
                                <b
                                  style="font-size: large; padding: 0 0 15px 0"
                                  >{{
                                    regime
                                      ? `${regime.protocol}°C`
                                      : ""
                                  }}</b
                                >
                              </div>
                           
                          </span>
                        </v-col>
                    <v-col cols="12" class="my-4">
                      <v-row justify="center">
                        <v-chip small class="mx-1" :key="updateVents"
                          >Vents: {{ container.vents }}
                          <span
                            v-if="
                              container.vents &&
                              !['CA', 'MA', 'CLOSED'].includes(container.vents.toUpperCase())
                            "
                          >
                            CBM</span
                          ></v-chip
                        >
                       
                        <v-chip
                          class="mx-1" :key="updateCA"
                          small
                          :color="
                            container && container.booking.caProduct
                              ? 'edit'
                              : ''
                          "
                          ><v-icon left>ac_unit</v-icon>
                          <span v-if="container.booking.caProduct"
                            >{{container.booking.caProduct}}</span
                          >
                          <span v-else>No CA</span></v-chip
                        >
                      </v-row>
               
                    </v-col>
                  </v-row>
                    </v-col>
                  </v-row>
  
                </v-card>
              </v-col>
  
        </v-row>
      </v-card-text>
  
      <v-dialog
        v-model="previewModal"
        width="1200px"
        :fullscreen="fullscreen || $vuetify.breakpoint.mobile"
      >
        <v-card v-if="item && item.name">
          <v-toolbar flat color="transparent">
            <v-toolbar-title>
              {{ item.name }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="downloadDocument(item)" icon
              ><v-icon>download</v-icon></v-btn
            >
            <v-btn @click="fullscreen = !fullscreen" icon
              ><v-icon v-if="!fullscreen">fullscreen</v-icon
              ><v-icon v-else>fullscreen_exit</v-icon></v-btn
            >
            <v-btn icon text @click="(previewModal = false), (item = {})"
              >X</v-btn
            >
          </v-toolbar>
          <v-card-text
            :style="{
              'max-height': fullscreen ? '90vh' : '75vh',
              'overflow-y': 'auto',
            }"
          >
            <v-row>
              <v-col cols="12" :loading="imageLoading">
                <div v-if="item.type && item.type.includes('image/')">
                  <v-img
                    :src="item.url"
                    contain
                    style="max-height: 70vh"
                    @load="imageLoading = false"
                  >
                  </v-img>
                </div>
                <div v-else-if="item.type == 'application/pdf'">
                  <v-row justify="center">
                    <v-col cols="12" sm="9" class="text-center" v-if="item.url">
                      <div ref="pdfBox">
                        <VuePdfEmbed
                          :width="fullscreen ? 1200 : 900"
                          :source="item.url"
                          @loaded="imageLoading = false"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <v-container v-else fill-height>
                  <v-row justify="center" height="100%">
                    <div class="text-center">
                      <span
                        >This file is in a format that cannot be previewed.</span
                      >
                      <br /><br />
                      <v-btn
                        color="primary"
                        style="text-transform: none"
                        @click="downloadDocument(item)"
                      >
                        <v-icon class="mr-1">download</v-icon> Download</v-btn
                      >
                    </div>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </template>
  
  <script>
  import dateFormat from "dateformat";
  // import GmapCustomMarker from "vue2-gmap-custom-marker";
  import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
  import isContainer from "../../../utils/containerNo";
  export default {
    props: ["booking", "containerData"],
    components: {
      // GmapCustomMarker,
      VuePdfEmbed,
    },
    data: () => ({
      center: { lat: 0.0, lng: 0.0 },
      change: false,
      contractors: [],
      regime: null,
      container: [],
      loadingCto: false,
      milestones: [],
      fullscreen: false,
      loadingContractors: false,
      loadingDetails: true,
      loadingComments: true,
      sendingMessage: false,
      containerChatArea: "",
      chatContainerUpdateKey: 0,
      updateVents: 0,
      updateCA: 0,
      imageLoading: true,
      item: {},
      legs: [],
      loadedDistance: 0,
      key: 50,
      mapStyle: {
        scrollwheel: true,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles: [
          { elementType: "geometry", stylers: [{ color: "#283a54" }] },
          { elementType: "labels.text.stroke", stylers: [{ color: "#283a54" }] },
          { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
          {
            featureType: "administrative.locality",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#263c3f" }],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [{ color: "#6b9a76" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#38414e" }],
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#212a37" }],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9ca5b3" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{ color: "#746855" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#1f2835" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [{ color: "#f3d19c" }],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [{ color: "#2f3948" }],
          },
          {
            featureType: "transit.station",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#212121" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [{ color: "#515c6d" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#17263c" }],
          },
        ],
      },
      milestoneTypes: [
        {
          name: "Empty Pickup",
          eventType: "TRANSPORT",
          modeOfTransport: "TRUCK",
          value: "EMPTY_PICKUP",
        },
        {
          name: "Stuff Date",
          eventType: "EQUIPMENT",
          modeOfTransport: "TRUCK",
          value: "STUFF_DATE",
        },
        {
          name: "Loading Point 1",
          eventType: "TRANSPORT",
          modeOfTransport: "TRUCK",
          value: "LOADING_POINT_1",
        },
        {
          name: "Loading Point 2",
          eventType: "TRANSPORT",
          modeOfTransport: "TRUCK",
          value: "LOADING_POINT_2",
        },
        {
          name: "Weigh Bridge",
          eventType: "TRANSPORT",
          modeOfTransport: "TRUCK",
          value: "WEIGH_BRIDGE",
        },
        {
          name: "Plugged In",
          eventType: "TRANSPORT",
          modeOfTransport: "TRUCK",
          value: "PLUG_IN",
        },
        {
          name: "Unplugged",
          eventType: "TRANSPORT",
          modeOfTransport: "TRUCK",
          value: "PLUG_OUT",
        },
        {
          name: "Port Holding",
          eventType: "TRANSPORT",
          modeOfTransport: "TRUCK",
          value: "FULL_DROP_OFF",
        },
      ],
      previewModal: false,
      statuses: [
        { id: 4, state: "AT", color: "#006bc3", location: null },
        { id: 3, state: "ASSIGNED", color: "#0094db", location: null },
        { id: 8, state: "COMPLETED", color: "#008a61", location: null },
        { id: 6, state: "DONE LOADING", color: "#2cc194", location: null },
        { id: 5, state: "EN ROUTE TO", color: "#ccb50a", location: null },
        { id: 7, state: "STOPPED", color: "#e55000", location: null },
        { id: 9, state: "CANCELLED", color: "#ff6666", location: null },
        { id: 10, state: "ISSUE", color: "#7f0000", location: null },
        { id: 2, state: "PENDING", color: "#657583", location: null },
        { id: 1, state: "TO BE ALLOCATED", color: "#b4bbc1", location: null },
      ],
      statusLocations: [
        { name: "LOADING POINT 1", value: "LP1" },
        { name: "LOADING POINT 2", value: "LP2" },
        { name: "EMPTY DEPOT", value: "EMPTY" },
        { name: "PORT OF LOADING", value: "POL" },
        { name: "DROP OFF", value: "DROPOFF" },
        { name: "YARD", value: "YARD" },
        { name: "FUEL STATION", value: "FUEL STATION" },
      ],
      savingChange: false,
      statusFilters: {
        at: false,
        assigned: false,
        completed: false,
        "done Loading": false,
        "en Route To": false,
        stopped: false,
        cancelled: false,
        issue: false,
        pending: false,
        "to Be Allocated": false,
      },
      transportStatuses: [
        {
          text: "Pending",
          value: "PENDING",
          color: "orange",
        },
        // {
        //     text: 'Booking Confirmed',
        //     value: 'BOOKING_CONFIRMED',
        //     color: 'green lighten-1'
        // },
        {
          text: "Pre-Allocated",
          value: "PRE_ALLOCATED",
          color: "green darken-2",
        },
        {
          text: "Allocated",
          value: "ALLOCATED",
          color: "lime darken-3",
        },
        {
          text: "Instr. Sent",
          value: "INSTRUCTION_SENT",
          color: "blue-grey",
        },
        {
          text: "Empty Out",
          value: "EMPTY_PICKUP",
          color: "teal",
        },
        {
          text: "En route LP-1",
          value: "EN_ROUTE_LP1",
          color: "cyan darken-2",
        },
        {
          text: "At LP-1",
          value: "AT_LP1",
          color: "cyan darken-4",
        },
        {
          text: "En route LP-2",
          value: "EN_ROUTE_LP2",
          color: "purple darken-2",
        },
        {
          text: "At LP-2",
          value: "AT_LP2",
          color: "purple darken-4",
        },
        {
          text: "En route WB",
          value: "EN_ROUTE_WB",
          color: "blue",
        },
        {
          text: "AT WB",
          value: "AT_WB",
          color: "blue lighten-1",
        },
        {
          text: "En route Depot",
          value: "EN_ROUTE_DEPOT",
          color: "blue darken-4",
        },
        {
          text: "At Depot",
          value: "AT_DEPOT",
          color: "blue darken-5",
        },
        {
          text: "En route Port",
          value: "EN_ROUTE_PORT",
          color: "blue darken-1",
        },
        {
          text: "At Port",
          value: "AT_PORT",
          color: "blue darken-2",
        },
        {
          text: "Gate In",
          value: "FULL_DROP_OFF",
          color: "success",
        },
      ],
      rules:{
        containerNo(value){
          if (/\b[A-Z]{4}\d{7}\b/.test(value)){
            return true;
          }
          return 'Container number needs to be in the format: ABCD1234567'
        }
      },
      params: {
        search: "",
        filter: {},
      },
    }),
    computed:{
      //take in my item and return thoose whoose product = TelemPlus
      filteredProducts(){
        return this.container.temporaryProducts.filter(product => product.product == 'TelemPlus')
      }
    },
    async created() {},
    watch: {
      "containerData.id": {
        immediate: true,
        handler(val) {
          if (val !== null) {
            this.getContainerDetails();
            this.getContainerComments(val);
          }
        },
      },
    },
    mounted() {
      this.getContractors();
    },
    methods: {
      async getContainerDetails() {
        this.container = JSON.parse(JSON.stringify(this.containerData))
        this.loadingDetails = true;
        let body = {
          regimeCode: this.container.regimeCode,
          containerId: this.container.id,
          bookingId: this.booking.id,
          regimeId: this.booking.regimeId,
        };
        let result = await this.$API.getContainer(body.containerId);
        if (result) {
          this.booking = result.booking
          this.container = {...this.container, ...result }
          this.regime = result.regime;
          this.milestones = result.containerMilestones;
          this.legs = result.legs ?? [];
          if (this.legs.length > 0) {
            this.center = this.legs[0].start;
            let findLoad = this.legs.findIndex(
              (leg) => leg.start.type == "LOADING_POINT_1"
            );
            let endLoad = this.legs.findIndex(
              (leg) => leg.start.type == "FULL_DROP_OFF"
            );
            if (findLoad > -1 && endLoad > -1) {
              let loadedLegs = this.legs.slice(findLoad, endLoad + 1);
              this.loadedDistance = 0;
              loadedLegs.forEach((leg) => {
                this.loadedDistance += parseFloat(leg.distance);
              });
              this.loadedDistance = this.loadedDistance.toFixed(2);
            }
          }
        }
        this.updateVents++
        this.updateCA++
        this.key++;
        this.loadingDetails = false;
      },
      async getContainerComments(containerId) {
        this.loadingComments = true;
        let result = await this.$API.getContainerComments(containerId);
        if (result) {
          this.container.containerComments = result;
  
          this.loadingComments = false;
          this.$nextTick(() => {
            let objDiv = document.getElementById("messagesContainer");
            if (objDiv) {
              objDiv.scrollTop = objDiv.scrollHeight;
            }
          });
        }
      },
      checkContainer(value) {
      if(isContainer(value)){
        return true
      }
      else{
        return false
      }
    },
      async commentContainer() {
        if (this.containerChatArea != "") {
          this.containerChatArea.trim();
          this.chatContainerUpdateKey++;
          // check if last 2 characters are not spaces or new line characters
          this.sendingMessage = true;
          let obj = {
            comment: this.containerChatArea,
            bookingContainerId: this.container.id,
            user: this.$store.state.user,
          };
          let result = await this.$API.commentOnContainer(obj);
          if (result) {
            result.user = this.$store.state.user;
            this.container.containerComments.push(result);
            this.containerChatArea = "";
            this.sendingMessage = false;
          } else {
            this.$message({
              type: "error",
              message: `Message could not go through. Please check your connection and try again.`,
            });
          }
        }
      },
      formatDate(date) {
        let isoFormat = null;
        let fullDate = null;
        let dateTime = null;
        let dayOfWeek = null;
        let shorterDate = null;
        let time = null;
        if (date) {
          isoFormat = dateFormat(new Date(date), "dd-mm-yyyy");
          fullDate = dateFormat(new Date(date), "dddd, mmmm dS, yyyy");
          dateTime = dateFormat(new Date(date), "dddd, mmmm dS, yyyy HH:MM");
          time = dateFormat(new Date(date), "HH:MM");
          dayOfWeek = dateFormat(new Date(date), "ddd");
          shorterDate = dateFormat(new Date(date), "mmm dS, yyyy");
        }
        return { isoFormat, fullDate, dateTime, dayOfWeek, shorterDate, time };
      },
      formatDateTime(date, time) {
        let result = null;
        if (date) {
          result = dateFormat(new Date(date), "dd-mm-yyyy");
          if(time){
          result += ' ' + time
        }
        }
        return result;
      },
      milestoneType(type) {
        let find = this.milestoneTypes.find((x) => x.value == type);
        return find ? find.name : null;
      },

      async downloadDocument(doc) {
        var hiddenElement = document.createElement("a");
        hiddenElement.href = doc.url;
        hiddenElement.target = "_blank";
        hiddenElement.download = doc.name;
        hiddenElement.click();
      },
      async generateCTO(id, container) {
        try {
          this.loadingCto = true;
          let obj = {
            containerId: id,
            movementType: container.booking.movementType,
          };
          let result = await this.$API.generateCTO(obj);
          if(result.error){
          this.$message.error(result.error)
          this.loadingCto = false;
          return
        }
        else{
          this.container.ctoDocument = result;
          this.container.ctoDocumentId = result.id;
          this.loadingCto = false;
        }
        } catch (e) {
          this.$message.error("An error ocurred :(");
          this.loadingCto = false;
        }
      },
      async getContractors() {
        this.loadingContractors = true
        let result = await this.$API.getContractors()
        if (result) {
          this.contractors = result
          this.loadingContractors = false
        }
      },
      getStatusColor(status) {
        let find = this.transportStatuses.find((x) => x.text == status);
        return find ? find.color : "grey";
      },
      matchColor(itemStatus) {
        let hex = "";
        let result = this.statuses.find((status) => status.state === itemStatus);
        if (result) {
          hex = result.color;
        }
        return hex;
      },
      closeDialog() {
        this.change = false;
        this.milestones = [];
        this.legs = [];
        this.regime = null;
        this.$emit("close");
      },
      setChange() {
        this.change = true;
      },
      setVGM(){
        this.change= true
        if(this.container.loadOutReceived){
          this.container.vgmWeight = parseInt(this.container.tareWeight) + parseInt(this.container.grossWeight)
        }
        console.log('setVgm',this.container.vgmWeight);
      },
      setPreadvised(preadvised){
        this.change= true
        this.container.manuallyPreadvised = preadvised
        if(preadvised){
          this.container.navisStatus = 'Approved'
        }
        else{
          this.container.navisStatus = ''
        }
      },
      async updateContainer() {
        this.savingChange = true;
        let status = this.container.transportStatus
        await this.$API.updateContainer({
          id: this.container.id,
          tareWeight: this.container.tareWeight,
          vgmWeight: this.container.vgmWeight,
          containerNo: this.container.containerNo,
          sealNo: this.container.sealNo,
          preadvised: this.container.preadvised,
          transporterId: this.container.transporterId,
          manuallyPreadvised: this.container.manuallyPreadvised,
          navisStatus: this.container.navisStatus,
          crossHaulTransporterId: this.container.crossHaulTransporterId
      });
        this.$message.success("Successfully updated!");
        this.savingChange = false;
        this.closeDialog();
      },
      updateTransporter(){
        if(this.container.transporter){
          this.container.transporterId = this.container.transporter.id
          this.container.transporterName = this.container.transporter ? this.container.transporter.alias ? this.container.transporter.alias : this.container.transporter.friendlyName ?? this.container.transporter.name : null
        } else {
          this.container.transporterId = null
          this.container.transporterName = null
        }
        if(!this.container.transporterId){
          this.container.transportStatus = 'Pending'
        } else {
          this.container.transportStatus = 'Allocated'
        }
        this.change = true
      },
      updateCrosshaulTransporter(){
        if(this.container.crossHaulTranporter){
          this.container.crossHaulTransporterId = this.container.crossHaulTranporter.id
          this.container.crossHaulTransporterName = this.container.crossHaulTranporter ? this.container.crossHaulTranporter.alias ? this.container.crossHaulTranporter.alias : this.container.crossHaulTranporter.friendlyName ?? this.container.crossHaulTranporter.name : null
        } else {
          this.container.crossHaulTransporterId = null
          this.container.crossHaulTransporterName = null
        }
        this.change = true
      },
      viewCTO(item) {
        this.item = item;
        if (
          this.item.type &&
          (this.item.type.includes("image/") ||
            this.item.type.includes("application/pdf"))
        ) {
          this.imageLoading = true;
        }
        this.previewModal = true;
      },
      // matchLocation(itemStatus) {
      //     let result = this.statusLocations.find(status => status.name === itemStatus).value
      //     return result
      // },
    },
  };
  </script>
  
  <style scoped>
  .localClass .v-timeline-item__body {
    --timeline-right-slot: 27vw;
    min-width: var(--timeline-right-slot - 30%);
    max-width: var(--timeline-right-slot);
    width: var(--timeline-right-slot);
  }
  
  .localClass.v-timeline--dense ::v-deep .v-timeline-item__opposite {
    --timeline-left-slot: 9vw;
    display: inline-block;
    text-align: end;
    flex: none;
    width: var(--timeline-left-slot);
    min-width: var(--timeline-left-slot);
    max-width: var(--timeline-left-slot);
  }
  
  .v-application--is-ltr
    .localClass.v-timeline--dense:not(.v-timeline--reverse)::before,
  .v-application--is-rtl .v-timeline--reverse.v-timeline--dense::before {
    left: calc(9vw + ((60px - 2px) / 2));
    width: 2px;
    height: 200%;
    right: auto;
  }
  
  .localClass ::v-deep .v-timeline-item__divider {
    min-width: 60px;
  }
  
  .map-marker {
    /* position: relative; */
  }
  
  .map-marker::after {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    /* top: -5px; */
    bottom: -20px;
    left: 10px;
    border: 10px solid transparent;
    border-top: 17px solid black;
  }
  </style>